import { Environment } from './interface';

export const environment: Environment = {
  production: false,
  useMocks: false,
  apiBaseUrl: 'https://api-dev.latchaccess.com',
  appDeepLinkUrl: 'https://app-dev.latch.com',
  enableCalendarLink: true,
  auth0Config: {
    domain: 'auth.dev.latchaccess.com',
    clientId: 'YTOC2iQb6ur3LQ1np44SF5A7gglIP1lQ',
    redirectUri: window.location.origin,
    audience: 'https://api-dev.latchaccess.com',
    serverUrl: 'https://api-dev.latchaccess.com',
    httpInterceptor: {
      allowedList: [
        'https://api-dev.latchaccess.com/*'
      ]
    }
  },
};
